import React, { useState } from "react";
import Router from 'next/router';

import AppContext from "./AppContext";

const AppProvider = (props) => {
    const [store, setStore] = useState({
        token: null,
        logout: (e) => {
          Router.push('/login');
          localStorage.removeItem('token');
          setStore({...store, token: null});
        },
    });

    return (
        <AppContext.Provider value={[store, setStore]}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppProvider;
