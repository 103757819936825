import { useContext, useEffect } from "react";
import Link from "next/link";

import { Button, Menu, Dropdown } from "antd";
import { HomeOutlined, DownOutlined } from "@ant-design/icons";

import AppContext from "./_context/AppContext";

import styles from "../styles/Layout.module.scss";

export default function AppLayout({ children }) {
  const [store, setStore] = useContext(AppContext);

  const toggleSidebar = (e) => {
    setStore({ ...store, sidebarCollapsed: !store.sidebarCollapsed });
  };

  useEffect(() => {
    if (null === store.token) {
      const t = localStorage.getItem("token");
      const localToken = JSON.parse(t);
      if (localToken) {
        setStore({ ...store, token: localToken });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <Menu className={styles.submenu}>
      {!store.token?.user_role.includes("saisie-allowa") && !store.token?.user_role.includes("saisie-allowa-invest") &&
        <Menu.Item key="new-investisseur">
          <Link href="/nouvelle-fiche-investisseur" passHref>
            <a>Investisseur</a>
          </Link>
        </Menu.Item>
      }
      <Menu.Item key="new-contrepartie">
        <Link href="/nouvelle-fiche-contrepartie" passHref>
          <a>Contrepartie Pers. Morale</a>
        </Link>
      </Menu.Item>
      <Menu.Item key="new-contrepartie-physique">
        <Link href="/nouvelle-fiche-contrepartie-physique" passHref>
          <a>Contrepartie Pers. Physique</a>
        </Link>
      </Menu.Item>
      {!store.token?.user_role.includes("saisie-allowa") && !store.token?.user_role.includes("saisie-allowa-invest") &&
        <Menu.Item key="new-autres-tiers">
          <Link href="/nouvelle-fiche-autres-tiers" passHref>
            <a>Autres Tiers</a>
          </Link>
        </Menu.Item>
      }
    </Menu>
  );

  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <div className={styles.header_inner}>
          <h1 className={styles.header_title}>
            <img
              src="/logo-scaprim.svg"
              width="42"
              height="46"
              alt="Logo Scaprim Nateo"
              style={{ marginRight: 14 }}
            />
            <img src="/logo-nateo.svg" alt="Logo Scaprim Nateo" />
          </h1>
          <div className={styles.menu}>
            <Link href="/accueil" passHref>
              <a className={styles.header_gotoHome}>
                <HomeOutlined />
                Accueil
              </a>
            </Link>
            {!store.token?.user_role.includes("twentytwo") ?
              <Dropdown overlay={menu}>
                <a
                  className={styles.header_gotoHome}
                  onClick={(e) => e.preventDefault()}
                >
                  {`Créer une relation d'affaires`} <DownOutlined />
                </a>
              </Dropdown>
            : null}
            <Link href="/toutes-les-fiches" passHref>
              <a className={styles.header_gotoHome}>Consulter les fiches</a>
            </Link>
            {!store.token?.user_role.includes("twentytwo") ?
              <Link href="/actualiser-une-fiche" passHref>
                <a className={styles.header_gotoHome}>Actualiser une fiche</a>
              </Link>
            : null}
          </div>
          {store.token && (
            <div>
              <span className={styles.user}>
                <img src="/user.svg" alt="" width={24} height={24} style={{marginRight: 10}} />
                {`Bonjour ${store.token.user_firstname}`} -{" "}
              </span>
              <Button className={styles.logout} onClick={store.logout}>
                Se déconnecter
              </Button>
            </div>
          )}
        </div>
      </header>
      <div className={styles.content}>
        <div className={styles.main}>{children}</div>
        <footer className={styles.footer}>Copyright Scaprim REIM - 2021</footer>
      </div>
    </div>
  );
}
