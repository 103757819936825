import { ConfigProvider } from "antd";
import "moment/locale/fr";
import locale from "antd/lib/locale/fr_FR";

import AppProvider from "../src/common/_context/AppProvider";

import "../src/styles/globals.scss";
import "antd/dist/antd.css";
import AppLayout from "@/common/AppLayout";

function MyApp(props) {
  const { Component, pageProps } = props;

  return (
    <AppProvider>
      <ConfigProvider locale={locale}>
        {"/login" !== props.router.pathname &&
        "/" !== props.router.pathname &&
        "/mot-de-passe-oublie" !== props.router.pathname ? (
          <AppLayout>
            <Component {...pageProps} />
          </AppLayout>
        ) : (
          <Component {...pageProps} />
        )}
      </ConfigProvider>
    </AppProvider>
  );
}

export default MyApp;
